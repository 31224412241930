import Phaser from "phaser";

export default class PlinkoScene extends Phaser.Scene {

	rect;

	preload() {
		this.load.image("background", "background.png");
		this.load.image("foreground", "background_bottom.png");
		this.load.image("ball", "pangball.png");
		this.load.image("button", "button.png");
		this.load.audio("impact", ["impact.mp3", "impact.ogg"]);
		this.load.audio("success", ["success.ogg"]);
	}

	create() {
		this.matter.world
			.setBounds(177, 0, 675, 700, 100, true, true, false, true)
			.setGravity(0, 0.6)

		// this.cameras.main.setBackgroundColor(new Phaser.Display.Color(244, 244, 244));
		this.add.image(80, 437, "background");
		let foreground = this.add.image(512, 634, "foreground")
			.setDepth(2);

		let line = new Phaser.Geom.Line(200, 70, 830, 70);
		// let graphics = this.add.graphics({ lineStyle: { width: 2, color: 0xff00ff }, fillStyle: { color: 0xff0000 }});
		// graphics.strokeLineShape(line);

		let impact = this.sound.add("impact");
		const distance = 54.3;
		const distanceY = 106;
		for (let i = 0; i < 12; i++) {
			for (let j = 0; j < 5; j++) {
				this.matter.add.circle(218 + distance * i, 131 + distanceY * j, 6, {
					isStatic: true,
					onCollideCallback: pair => {
						impact.play();
					}
				});
			}
		}

		for (let i = 0; i < 13; i++) {
			for (let j = 0; j < 4; j++) {
				this.matter.add.circle(188 + distance * i, 183 + distanceY * j, 6, {
					isStatic: true,
					onCollideCallback: pair => {
						impact.play();
					}
				});
			}
		}

		this.matter.add.rectangle(174, 630, 20, 80, {isStatic: true});
		this.matter.add.rectangle(305, 630, 20, 80, {isStatic: true});
		this.matter.add.rectangle(388, 630, 20, 80, {isStatic: true});
		this.matter.add.rectangle(505, 630, 20, 80, {isStatic: true});
		this.matter.add.rectangle(563, 630, 20, 80, {isStatic: true});
		this.matter.add.rectangle(771, 630, 20, 80, {isStatic: true});
		this.matter.add.rectangle(850, 630, 20, 80, {isStatic: true});

		var sprite = this.add.sprite(899, 670, "button").setInteractive().setDepth(3);
		sprite.on("pointerdown", function (pointer) {
			this.setTint(0xff0000);
		});
		sprite.on("pointerup", function (pointer) {
			sprite.clearTint();
			let p = line.getRandomPoint();
			const ball = this.matter.add.image(p.x, p.y, "ball");
			ball.setScale(0.65);
			ball.setCircle(12);
			ball.setFriction(0.4);
			ball.setBounce(1.0);
			ball.setDepth(1);
		}, this);

		let success = this.sound.add("success");
		let bottom = this.matter.add.rectangle(400, 710, 1000, 100, {
			isStatic: true,
			onCollideCallback: pair => {
				pair.bodyB.destroy();
				success.play();
			}
		});
	}
}