import Phaser from "phaser";

import PlinkoScene from "./scenes/PlinkoScene";

const config = {
	type: Phaser.AUTO,
	width: 1024,
	height: 710,
	physics: {
		default: "matter",
		matter: {
			enableSleeping: true,
			debug: false,
		}
	},
	scene: [PlinkoScene]
};

export default new Phaser.Game(config);